class Navigation {
    
    constructor(props) {
        this.props = props

        this.props.nav = this.props.elem.substring(1)

        this.init()
    }

    init() {

        if ( typeof this.props.elem === 'undefined' ) return

        this.navWrapper = document.querySelector(this.props.elem)

        this.body = document.getElementsByTagName('body')[0]

        if ( this.navWrapper == null ) return
        
        this.navMenu = document.querySelector(this.props.navMenu)

        if ( typeof this.props.toggleBtn !== 'undefined' ) {
            this.toggleBtn = document.querySelector(this.props.toggleBtn)

            this.addToggleNav()
        }

        if ( typeof this.props.openBtn !== 'undefined' 
            && typeof this.props.closeBtn !== 'undefined' ) {
            this.openBtn = document.querySelector(this.props.openBtn)
            this.closeBtn = document.querySelector(this.props.closeBtn)

            this.addOpenCloseNav()
        }
    }

    addToggleNav() {

        this.collapseNav(this.toggleBtn)

        this.toggleBtn.addEventListener('click', (e) => {
            e.preventDefault()

            if(this.toggleBtn.classList.contains('expanded')) {
                this.closeNav(this.toggleBtn)
            } else {
                this.openNav(this.toggleBtn)
            }
        })
    }

    addOpenCloseNav() {
        this.collapseNav(this.openBtn)

        this.openBtn.addEventListener('click', (e) => {
            e.preventDefault()

            if(this.openBtn.classList.contains('expanded')) {
                this.closeNav(this.openBtn)
            } else {
                this.openNav(this.openBtn)
            }
        })

        this.closeBtn.addEventListener('click', (e) => {
            e.preventDefault()

            if(this.openBtn.classList.contains('expanded')) {
                this.closeNav(this.openBtn)
            } else {
                this.openNav(this.openBtn)
            }
        })
    }

    openNav(btn) {
        btn.classList.add('expanded')
        btn.setAttribute('aria-expanded', 'true')
        this.navWrapper.classList.add('expanded')
        this.navMenu.classList.add('show')
        this.navMenu.classList.remove('hide')
        
        this.body.classList.add(`${this.props.nav}-expanded`)
    }
    
    closeNav(btn) {
        btn.classList.remove('expanded')
        btn.setAttribute('aria-expanded', 'false')
        this.navWrapper.classList.remove('expanded')
        this.navMenu.classList.remove('show')
        this.navMenu.classList.add('hide')

        this.body.classList.remove(`${this.props.nav}-expanded`)
    }

    collapseNav(btn) {
        document.addEventListener('click', (e) => {
            const isClickInside = this.navWrapper.contains(e.target)

            if ( !isClickInside && this.navWrapper.classList.contains('expanded') ) {
                this.closeNav(btn)
            }
        })
    }
}

export default Navigation