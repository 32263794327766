class Video {

    constructor(props) {
        this.props = props
        this.props.icon = props.icon || '.icon'
        this.props.iconPlay = props.iconPlay || 'icon-play'
        this.props.iconPause = props.iconPause || 'icon-pause'
        this.props.iconMute = props.iconMute || 'icon-volume-mute'
        this.props.iconVolumeUp = props.iconVolumeUp || 'icon-volume-up'
        this.init()
    }

    init() {

        if ( typeof this.props.elem === 'undefined' ) return

        this.videoWrapper = document.querySelector(this.props.elem)

        if ( this.videoWrapper == null ) return

        if ( this.videoWrapper.classList.contains('embed') ) return

        this.video = this.videoWrapper.querySelector('video')
        this.playPauseButton = this.videoWrapper.querySelector(this.props.playPause)
        this.soundButton = this.videoWrapper.querySelector(this.props.sound)
        
        this.addToggleControl()

        this.addToggleSound()
        
        // add play p
        this.eventPlayPause()
        
        // add mouse stop
        this.hideControl()
    }

    createToggleControl() {
        this.buttonWrapper = document.createElement('div')
        this.buttonWrapper.classList.add('button-wrapper')

        this.playPauseButton = document.createElement('button')
        this.playPauseButton.type = 'button'
        this.playPauseButton.classList.add('icon-control', )
    }

    addToggleControl() {

        this.playPauseButton.addEventListener('click', () => {
            
            if(this.video.paused) {
                this.video.play();
            } else {
                this.video.pause();
            }
        })
    }
    
    addToggleSound() {
        this.soundButton.addEventListener('click', () => {

            if(this.video.muted) {
                this.video.muted = false

                this.soundButton.classList.remove('mute')
                this.soundButton.classList.add('volume-up')
            } else {
                this.video.muted = true

                this.soundButton.classList.add('mute')
                this.soundButton.classList.remove('volume-up')
            }

        })
    }

    eventPlayPause() {
        this.video.addEventListener('play', () => {

            this.playPauseButton.classList.add('pause')
            this.playPauseButton.classList.remove('play')
            
        })
        
        this.video.addEventListener('pause', () => {

            this.playPauseButton.classList.add('play')
            this.playPauseButton.classList.remove('pause')
            
        })
    }

    hideControl() {

        let i = null;
        
        this.videoWrapper.addEventListener('mousemove', () => {

            clearTimeout(i)

            let buttonWrapper = this.playPauseButton.parentNode

            buttonWrapper.classList.add('show')

            i = setTimeout(() => {
                buttonWrapper.classList.remove('show')
            }, 3000)
        })
    }

    onPlayerReady(event) {
        event.target.playVideo()
    }

    onPlayerStateChange(event) {
        if (event.data == this.YT.PlayerState.PLAYING && !this.done) {
            setTimeout(() => {
                this.stopVideo()
            }, 6000)
            this.done = true
        }
    }

    stopVideo() {
        this.player.stopVideo();
    }
}

export default Video